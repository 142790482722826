import React, { useState, useEffect, useRef } from "react";
import { 
  Paper,
  Modal, 
  Box, 
  Typography, 
  Button,
  Divider,
  Table, 
  TableContainer,
  TableBody, 
  TableRow, 
  TableCell, 
  TextField, 
  Select, 
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  Tooltip } 
from '@mui/material';
import { InputGroupAddon } from "reactstrap";
// import DatePicker from "react-datepicker";
// import Select from "react-select";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import database from "../../service/firebaseConfig";
import { toast } from "react-toastify";
import UserEmail from "../../CommonRequirements/FetchUserEmailId";
import UpdatedDate from "../../CommonRequirements/UpdatedDate";
import UpdatedTime from "../../CommonRequirements/UpdatedTime";
// import Creatable from "react-select/creatable";
// import Switch from "react-switch";
// import request from "request";
import baseUrl from "../../service/servicesConfig";
import { LOGGED_USER_EMAIL } from "../../constants/allConstants";
import { getUserType, validateIsAdminUser } from "../../service/credentials";
import moment from "moment";

const SuppliersDB = database.database().ref(`Suppliers`);
const PurchaseDetailsData = database.database().ref(`Purchase_Details`);
const Product_Base = database.database().ref(`Product_Base_Data`);

const sessionDate = new Date();
let sessionMonth = sessionDate.getMonth() + 1;
if (sessionMonth < 10) sessionMonth = "0" + sessionMonth;
let sessionDt = sessionDate.getDate();
if (sessionDt < 10) sessionDt = "0" + sessionDt;

function EditGRNModal({
  open, 
  onClose,
  data,
  itemCode,
  selectedDate,
  selectedMonth,
  selectedYear,
  productMasterData,
  setAllEditItemDetails, // mutating this state here from NewListPurchaseAddAndEdit.js
  setEditSaveClick, // mutating this state in NewListPurchaseAddAndEdit.js
  supplierName,
  selectedPO_Item,
  ...props
}) {
  const [suppliersList, setSuppliersList] = useState([]);

  const [productEditObject, setProductEditObject] = useState({});
  const [materialReceivingDt, setMaterialReceivingDt] = useState(
    data.grn_date && data.grn_date !== ""
      ? new Date(
          data.grn_date.split("-")[0],
          data.grn_date.split("-")[1] - 1,
          data.grn_date.split("-")[2]
        )
      : new Date()
  );
  const [bagRecieved, setBagRecieved] = useState(data.bags_received || "");
  const [totalWtReceived, setTotalWtReceived] = useState(
    Number(data.total_weight_received)
  );
  const [invoiceNoList, setInvoiceNoList] = useState("");
  const [updateGrn, setUpdateGrn] = useState(false);
  const [billSubmitConfirm, setBillSubmitConfirm] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [replaceBrand, setReplaceBrand] = useState(data.replacement_brand || "");
  const [purchasePrice, setPurchasePrice] = useState(data.purchase_price || "")
  const [errors, setError] = useState({ // All Mandatory fields Check
    replacement_brand:"",
    no_of_bags:"",
    purchase_price:""
  });
  const [invoiceDate, setInvoiceDate] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [invoiceRatePer, setInvoiceRatePer] = useState(Number(data.purchase_price));
  const [allUpdatingEditItemsHash, setAllUpdatingEditItemsHash] = useState({})

  const diffWtRef = useRef();
  const totalReceivedWt = useRef();
  const bagReceivedRef = useRef(null);
  const supplierRef = useRef();
  
  console.log("1",diffWtRef.current)
  console.log("2",totalReceivedWt.current)
  console.log("3",bagReceivedRef.current)
  console.log("4",supplierRef.current)

const [schemeAmount,setSchemeAmount] = useState("")

const [poAmount,setPoAmount] = useState("")
const [gstCharge,setGstCharge] = useState("")
const [apmcCharge,setApmcCharge] = useState("")
useEffect(()=>{

  // invoice_amt is po amount for that item
  const getGST_Charges = () => {
    const calculatedCharge = (parseFloat(data.gstPerFromFb || data.gst_charges_per) * parseFloat(poAmount)) / 100;
    return calculatedCharge
  };

  const calculatedGstCharge = getGST_Charges()
  setGstCharge(calculatedGstCharge)

 const getAPMC_Charges = () => {
    const calculatedCharge = (parseFloat(data.apmcPerFromFb ||  data.apmc_charges_per) * parseFloat(poAmount)) / 100;
    return calculatedCharge
  };

  const calculatedApmcCharge = getAPMC_Charges()
  setApmcCharge(calculatedApmcCharge)

},[poAmount])


  function formatDates(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }


  const handleBagsReceivedChange = (value) => {
    // const bagsReceived = parseInt(value, 10);
    setProductEditObject((prev) => ({
      ...prev,
      bags_received: value,
      invoice_amt: Number(data.outer_size) * value * invoiceRatePer,
    }));
    setBagRecieved(value);
    // setTotalWtReceived(Number(data.outer_size) * value);
    setTotalWtReceived(Number(data.total_weight_received));
    setInvoiceRatePer(data.purchase_price)

    setError({ 
      replacement_brand:"",
      no_of_bags:"",
      purchase_price:""
    })

    setPurchasePrice(data.purchase_price)

    setPoAmount(Number(data.total_weight_received)*Number(data.purchase_price))
    // setGstCharge("")
    // setApmcCharge("")
  };

  // Run the logic when the modal opens up
  // to reinitialize the values of all the shared states
  useEffect(() => {
    if (open) {
      handleBagsReceivedChange(parseFloat(data.bags_received) || '');
    }
  }, [open]);



  // making a predefined hash map items for Edit
  // all the items will be passed together in case of edit
  useEffect(()=>{
   const allEditItemshash={}
   
    for(let object of selectedPO_Item)
    {

    
      const obj={
        // this should be identified because of new values total_weight_received and grn_date
        // the new values shold not be updated
        type:"non-editable",

        // adding these keys for calculation of generic values in NewListPurchaseAddAndEdit.js
        outer_size:object.outer_size,
        bag_purchased:object.bag_purchased,
        purchase_price:object.purchase_price,
        apmc_charges:object.apmc_charges,
        gst_charges:object.gst_charges,

        bags_received:object.bags_received,
        base_price:object.base_price,
        bill_submitted:object.bill_submitted,
        diff_receiving:object.diff_receiving,
        expiry_date:object.expiry_date,
        grn_date: object.grn_date,       //formatDates(new Date()), // new values should not be entered here
        invoice_amt:object.invoice_amt,
        isAdmin:validateIsAdminUser(
          sessionStorage.getItem(LOGGED_USER_EMAIL)
        ),                       // sessionStorage.getItem(LOGGED_USER_EMAIL),
        item_id:object.article_code,
        old_grn_date:object.grn_date, // requires old value
        old_total_weight_received:object.total_weight_received, // requires old value
        payment_date:object.payment_date,
        purchase_details_key:object.uniqueKey,
        purchased_date:object.purchased_date,
        replacement_brand:object.replacement_brand,
        total_weight_received:object.total_weight_received,  // new values should not be entered here
        updated_at:object.updated_at,
        updated_by:object.updated_by,

        po_amount:object.po_amount, // po amount at item level
        
        gstPerFromFb:object.gstPerFromFb, // using at generic level calculation to identify exclude or include during calculation at NewListPurchaseAddAndEdit.js
        apmcPerFromFb:object.apmcPerFromFb,
        gst_charges_per:object.gst_charges_per, // using at generic level calculation to identify exclude or include during calculation at NewListPurchaseAddAndEdit.js
        indexVal:object.indexVal // being used to map the updated state value of bag purchased at NewListPurchaseAddAndEdit.js
       }

      const keyValue=object.indexVal
      allEditItemshash[keyValue]=obj
    }
    setAllUpdatingEditItemsHash(allEditItemshash)
  },[])


 
  useEffect(()=>{
    const addedItemsListOfObjects=[]
    for(let key in allUpdatingEditItemsHash)
    {
        // making list of objects from hash map removing keys and only storing the values
        addedItemsListOfObjects.push(allUpdatingEditItemsHash[key])
    }
    setAllEditItemDetails(addedItemsListOfObjects)
  },[allUpdatingEditItemsHash])

 
   // invoice_amt is po amount for that item
  const getGSTCharges = () => {
    let gst_per = data.gst_charges_per
      ? Number(data.gst_charges_per.split("%")[0] || data.gstPerFromFb) // || data.gst_charges_per
      : 0;
      
    let invoice_amt = productEditObject?.invoice_amt
      ? Number(productEditObject.invoice_amt)
      : Number(data.invoice_amt);

    return (gst_per * invoice_amt) / 100;
  };

  const getAPMCCharges = () => {
    let apmc_per = data.apmc_charges_per
      ? Number(data.apmc_charges_per.split("%")[0]  || data.apmcPerFromFb) // || data.apmc_charges_per
      : 0;
   
    let invoice_amt = productEditObject?.invoice_amt
      ? Number(productEditObject.invoice_amt)
      : Number(data.invoice_amt);
    return (apmc_per * invoice_amt) / 100;
  };

  const getTransportCharges = () => {
    let transport_charge = productEditObject?.transport_charges
      ? productEditObject.transport_charges
      : data.transport_charges
      ? data.transport_charges
      : 0;
    return Number(transport_charge) * totalWtReceived;
  };

  const CustomDateInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black" }}
    />
  );

  const updatePurchaseDetailsNew = async () => {

    // if(!bagRecieved || parseFloat(bagRecieved)<0)
    // {
    //   setError({replacement_brand:"",no_of_bags:"No of bags is required and it cannot be less than 0."});
    //   return toast("Enter Bag Received", { type: toast.TYPE.ERROR });
    // }
    // else if (!replaceBrand) {
    //   setError({no_of_bags:"",replacement_brand:"Replacement Brand is required."});
    //   return toast("Enter Replacement Brand", { type: toast.TYPE.ERROR });
    // } 
    // else if(!purchasePrice)
    // { 
    //   setError({no_of_bags:"",replacement_brand:"",purchase_price:"Purchase Price is required"});
    //   return toast("Enter Purchase Price", { type: toast.TYPE.ERROR });
    // }
    // else {
    //   setError('');
    //   console.log('Form submitted');
    // }

    setUpdateGrn(false);
    console.log(productEditObject);
    let newSupplier = true;


    if (productEditObject["supplier_name"]) {
      suppliersList.forEach((item) => {
        if (productEditObject["supplier_name"] == item.value) {
          newSupplier = false;
        }
      });
      if (newSupplier)
        SuppliersDB.push({ supplier_name: productEditObject["supplier_name"] });
    }

    const firebaseItemCode = itemCode.substring(11);

    console.log(
      "firebaseItemCode: ",
      firebaseItemCode,
      " itemCode: ",
      itemCode,
      "productEditObject: ",
      productEditObject,
      " materialReceivingDt: ",
      materialReceivingDt,
      " productEditObject: ",
      productEditObject
    );

    const materialReceivingDate = new Date(materialReceivingDt);
    const year = materialReceivingDate.getFullYear();
    const month =
      materialReceivingDate.getMonth().toString().length === 1
        ? `0${materialReceivingDate.getMonth() + 1}`
        : materialReceivingDate.getMonth() + 1;
    const date =
      materialReceivingDate.getDate().toString().length === 1
        ? `0${materialReceivingDate.getDate()}`
        : materialReceivingDate.getDate();

    const grn_date_format = `${year}-${month}-${date}`;

    console.log(
      "grn_date_format: ",
      grn_date_format,
      " purchase_date: ",
      data.purchased_date
    );

    const isAdmin = validateIsAdminUser(
      sessionStorage.getItem(LOGGED_USER_EMAIL)
    );

    console.log("isAdmin: ", isAdmin);

    const grn_date_obj = new Date(grn_date_format);
    const purchased_date_obj = new Date(data.purchased_date);
    const grn_date_millis = grn_date_obj.getTime();
    const purchased_date_millis = purchased_date_obj.getTime();
    let fourDaysOlderDate = new Date();

    const currYear = fourDaysOlderDate.getFullYear();
    const currMonth =
      fourDaysOlderDate.getMonth().toString().length === 1
        ? `0${fourDaysOlderDate.getMonth() + 1}`
        : fourDaysOlderDate.getMonth() + 1;
    const currDate =
      fourDaysOlderDate.getDate().toString().length === 1
        ? `0${fourDaysOlderDate.getDate()}`
        : fourDaysOlderDate.getDate();

    fourDaysOlderDate = new Date(`${currYear}-${currMonth}-${currDate}`);
    fourDaysOlderDate = fourDaysOlderDate.setDate(
      fourDaysOlderDate.getDate() - 4
    );

    let last30DaysDate = new Date();
    last30DaysDate = new Date(`${currYear}-${currMonth}-${currDate}`);
    last30DaysDate = last30DaysDate.setDate(last30DaysDate.getDate() - 30);
    if (!isAdmin) {
      if (new Date(grn_date_format) < new Date(last30DaysDate)) {
        return toast("GRN Date can not be more than 30 days old", {
          type: toast.TYPE.ERROR,
        });
      }
    }
    let payment_terms = Number(data.payment_terms);
    let payment_date = moment(new Date(data.grn_date))
      .clone()
      .add(payment_terms, "day");
    const formattedPaymentDate = payment_date.format("YYYY-MM-DD");

    

    const jsonObj = {
      ...productEditObject,
      invoice_amt:poAmount || data.po_amount,
      bags_received: bagRecieved,            
      diff_receiving: diffWtRef.current.value,
      total_weight_received: totalWtReceived , //totalReceivedWt.current.value,
      bill_submitted: billSubmitConfirm !== "" ? "" + billSubmitConfirm : "",
      base_price: basePrice ? basePrice : data.base_price,
      replacement_brand: replaceBrand
        ? replaceBrand
        : data.replacement_brand
        ? data.replacement_brand
        : "",
      updated_by: UserEmail(),
      updated_at: `${UpdatedDate()} ${UpdatedTime()}`,
      isAdmin: isAdmin,
      purchased_date: data.purchased_date,
      purchase_details_key: data.uniqueKey,
      payment_date: formattedPaymentDate,
      expiry_date: productEditObject?.expiry_date
        ? productEditObject.expiry_date
        : `${
            new Date().getDate() < 10
              ? "0" + new Date().getDate()
              : new Date().getDate()
          }-${
            new Date().getMonth() + 1 < 10
              ? "0" + (new Date().getMonth() + 1)
              : new Date().getMonth() + 1
          }-${new Date().getFullYear()}`,
    };

    const old_grn_date = data.grn_date;
    const old_total_weight_received = data.total_weight_received;

    jsonObj.grn_date = grn_date_format;
    jsonObj.old_grn_date = old_grn_date;
    jsonObj.old_total_weight_received = old_total_weight_received;
    jsonObj.item_id = data.article_code
    jsonObj.outer_type = data.outer_type
    jsonObj.item_name = data.item_name
    jsonObj.active_status = productMasterData[data?.article_code].active_status

    // adding these keys for calculation of generic values in NewListPurchaseAddAndEdit.js
    jsonObj.outer_size=data.outer_size
    jsonObj.bag_purchased=data.bag_purchased

    jsonObj.purchase_price=Number(purchasePrice) || data.purchase_price
    jsonObj.apmc_charges=apmcCharge || data.apmc_charges
    jsonObj.gst_charges=gstCharge || data.apmc_charges

    // (Number(purchasePrice) * Number(totalWtReceived))
    jsonObj.po_amount=poAmount || data.po_amount

    jsonObj.gstPerFromFb=data.gstPerFromFb // using at generic level calculation to identify exclude or include during calculation at NewListPurchaseAddAndEdit.js
    jsonObj.apmcPerFromFb=data.apmcPerFromFb
    jsonObj.gst_charges_per=data.gst_charges_per // using at generic level calculation to identify exclude or include during calculation at NewListPurchaseAddAndEdit.js
    jsonObj.indexVal=data.indexVal // being used to map the updated state value of bag purchased at NewListPurchaseAddAndEdit.js
    // these objects are editable objects
    jsonObj.type="editable"

    console.log("Bande ka check obj", jsonObj);
    // const url = `/api/superzop/commercial/purchasedetails/addeditgrn?type=edit`;
    // baseUrl
    //   .post(url, jsonObj)
    //   .then((res) => {
    //     if (res.status == 200) {
    //       setProductEditObject({});
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 2000);
    //       notifyUser(true);
    //     }
    //   })
    //   .catch((error) => {
    //     toast("Failed to update System Inventory", { type: toast.TYPE.ERROR });
    //     console.log(error);
    //   });
    
    // newly modified objects will replace the old objects
    setAllUpdatingEditItemsHash({...allUpdatingEditItemsHash,[data.indexVal]:{...jsonObj}})
    setEditSaveClick(true)
    window.alert("Saved Successfully")
    onClose()
  }; 

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const notifyUser = (success) => {
    if (success) {
      return toast(`Updated the GRN for ${data.article_code}`, {
        type: toast.TYPE.SUCCESS,
      });
    }
    return toast("Couldn't update the GRN", { type: toast.TYPE.ERROR });
  };
  const fetchSuppliers = () => {
    const suppliers = [];
    const sortSupplierList = [];
    SuppliersDB.once("value", (snapShot) => {
      const suppliersList = snapShot.val();
      Object.keys(suppliersList).forEach((supplier) => {
        sortSupplierList.push({
          name: suppliersList[supplier].supplier_name,
          email: suppliersList[supplier].EmailID,
          key: suppliersList[supplier].supplier_id,
          fssai: suppliersList[supplier].fssai,
          gstin: suppliersList[supplier]["GST_Identification_Number_(GSTIN)"],
          phone: suppliersList[supplier].MobilePhone,
          address: suppliersList[supplier].Billing_Address,
          street: suppliersList[supplier].Billing_Street2,
          city: suppliersList[supplier].Billing_City,
          pin: suppliersList[supplier].Billing_Code,
          state: suppliersList[supplier].Billing_State,
        });
      });
      sortSupplierList.sort();
      sortSupplierList.forEach((items) => {
        suppliers.push({
          value: items.name,
          label: items.name,
          email: items.email,
          key: items.key,
          fssai: items.fssai,
          gstin: items.gstin,
          phone: items.phone,
          address: items.address,
          street: items.street,
          city: items.city,
          pin: items.pin,
          state: items.state,
        });
      });
    }).then(() => {
      setSuppliersList(suppliers);
    });
  };

  const fetchInvoiceNumber = () => {
    const invoiceNumberList = [];
    let allInvoiceNumbers = [];

    const complete_date = `${selectedDate.split("-")[2]}-${
      selectedDate.split("-")[1]
    }-${selectedDate.split("-")[0]}`;

    console.log("fetchInvoiceNumber: ", complete_date);

    const url = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${complete_date}&purchase_end_date=${complete_date}`;
    baseUrl
      .get(url)
      .then((res) => {
        console.log("fetchInvoiceNumber: ", res);
        if (res.status === 200) {
          return res.data;
        }
      })
      .then((purchaseList) => {
        Object.keys(purchaseList).forEach((items) => {
          if (purchaseList[items].invoice_number)
            allInvoiceNumbers.push(purchaseList[items].invoice_number);
        });
        allInvoiceNumbers = [...new Set(allInvoiceNumbers)];
        allInvoiceNumbers.forEach((invoices) => {
          invoiceNumberList.push({ value: invoices, label: invoices });
        });
      })
      .then(() => {
        console.log(
          "fetchInvoiceNumber invoiceNumberList: ",
          invoiceNumberList
        );
        setInvoiceNoList(invoiceNumberList);
      });
  };

  const createGrnNumber = () => {

    if(!bagRecieved || parseFloat(bagRecieved)<0)
    {
      setError({replacement_brand:"",no_of_bags:"No of bags is required and it cannot be less than 0."});
      return toast("Enter Bag Received", { type: toast.TYPE.ERROR });
    }
    else if (!replaceBrand) {
      setError({no_of_bags:"",replacement_brand:"Replacement Brand is required."});
      return toast("Enter Replacement Brand", { type: toast.TYPE.ERROR });
    } 
    else if(!purchasePrice)
    { 
      setError({no_of_bags:"",replacement_brand:"",purchase_price:"Purchase Price is required"});
      return toast("Enter Purchase Price", { type: toast.TYPE.ERROR });
    }
    else {
      setError('');
      console.log('Form submitted');
    }

    
    console.log("GRN");
    let countCheck = 0;
    if (
      data.invoice_number &&
      !productEditObject["invoice_number"] &&
      data.supplier_name &&
      !productEditObject["supplier_name"]
    ) {
      setUpdateGrn(true);
      return;
    }

    if (data.supplier_name && !productEditObject["supplier_name"]) {
      setProductEditObject({
        ...productEditObject,
        supplier_name: data.supplier_name,
      });
    }

    if (!productEditObject["expiry_date"]) {
      setProductEditObject({
        ...productEditObject,
        expiry_date: formatDate(new Date()),
      });
    }


    const complete_date = `${selectedDate.split("-")[2]}-${
      selectedDate.split("-")[1]
    }-${selectedDate.split("-")[0]}`;

    console.log("createGrnNumber: ", complete_date);

    const url = `/api/superzop/commercial/purchasedetails/getpurchasedetailsbasedondate?purchase_start_date=${complete_date}&purchase_end_date=${complete_date}`;

    baseUrl
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .then((purchaseList) => {
        let conditionMatchGRN = false;
        Object.keys(purchaseList).forEach((items) => {
          if (
            productEditObject["invoice_number"] ===
            purchaseList[items].invoice_number
          ) {
            conditionMatchGRN = true;
            if (
              productEditObject["supplier_name"] !=
              purchaseList[items].supplier_name
            ) {
              toast("Supplier is not matching with Invoice Number", {
                type: toast.TYPE.ERROR,
              });
            }
            setProductEditObject({
              ...productEditObject,
              grn_no: purchaseList[items].grn_no
                ? purchaseList[items].grn_no
                : "",
            });
            setUpdateGrn(true);
            return;
          }
        });

        if (!conditionMatchGRN) {
          // Create GRN Number
          setProductEditObject({
            ...productEditObject,
            grn_no: `${selectedDate.split("-")[0]}${
              selectedDate.split("-")[1]
            }${selectedDate.split("-")[2].substring(0, 2)}${
              Math.floor(Math.random() * 90000) + 10000
            }`,
          });
          setUpdateGrn(true);
        }
      });
  };

  useEffect(() => {
    if (updateGrn) {
      updatePurchaseDetailsNew();
    }
  }, [updateGrn]);

  useEffect(() => {
    fetchSuppliers();
    fetchInvoiceNumber();
  }, []);


  const formatDate = (val) => {
    let date = new Date(val);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

  // trigerring the supplierInfo On suppplierName Value change that is comming from the prop/parent
  // because select here is disabled
  useEffect(() => {
    if (supplierName) {
      supplierRef.current=supplierName
      const selectedSupplier = suppliersList.find(supplier => supplier.value === supplierName);
      if (selectedSupplier) {
        setProductEditObject({
          ...productEditObject,
          supplier_name: selectedSupplier.value,
          supplier_id: selectedSupplier.key,
          supplier_email: selectedSupplier.email,
          bill_address: selectedSupplier.address,
          bill_city: selectedSupplier.city,
          bill_state: selectedSupplier.state,
          bill_street: selectedSupplier.street,
          bill_pin: selectedSupplier.pin,
          bill_fssai: selectedSupplier.fssai,
          bill_gstin: selectedSupplier.gstin,
          bill_phone: selectedSupplier.phone,
        });
      }
    }
    // window.alert(JSON.stringify(productEditObject))
  }, [supplierName, suppliersList]);

  const materialUiformatDate = (val) => {

    if(!val){
        return ''
    }

    if (typeof val === 'string' && val.includes('-')) {
        // Assuming the input is in "DD-MM-YYYY" format
        const [day, month, year] = val.split('-');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      }


    // On Change the value will be
    // Wed Jul 17 2024 05:30:00 GMT+0530 (India Standard Time) {}
    if (!(val instanceof Date)) {
      val = new Date(val);
    }

    // Ensure month and day are padded with leading zeros if necessary
    const month = String(val.getMonth() + 1).padStart(2, '0');
    const day = String(val.getDate()).padStart(2, '0');
    return `${val.getFullYear()}-${month}-${day}`;
  };


  // const finalInvoiceCalculationField = () => {

  //   const invoice_rate =  ( Number(data.outer_size || 0) * Number(bagRecieved || 0) * Number(invoiceRatePer || 0) )
  //   const gst = (gstCharge || 0) // Number(getGSTCharges() || 0) 
  //   const apmc =  (apmcCharge || 0) // Number(getAPMCCharges() || 0)  
  //   const transport = Number(getTransportCharges() || 0)
  //   const total = gst + apmc + transport
  
  //   const calculatedFinalInvoice = invoice_rate + total - Number(schemeAmount || 0)
      
  //   return Number(calculatedFinalInvoice)
        
  //   };


  const toNumber = (value, defaultValue = 0) => {
    const num = Number(value);
    return isNaN(num) ? defaultValue : num;
  };

  const finalInvoiceCalculationField = () =>
  {

  // Calculate invoice rate
  const invoice_rate = toNumber(data.outer_size) * toNumber(bagRecieved) * toNumber(purchasePrice);

  // Get additional charges
  const gst = toNumber(gstCharge);
  const apmc = toNumber(apmcCharge);
  const transport = toNumber(getTransportCharges());

  // Calculate total additional charges
  const total = gst + apmc + transport;

  // Calculate final invoice amount
  const calculatedFinalInvoice = invoice_rate + total - toNumber(schemeAmount);
  
  return calculatedFinalInvoice;
};


  const modalStyle = {
    position: 'relative',
    top: '50%',
    left: '50%',
   transform: 'translate(-50%, -56%)',
    width: '100vw',
    height:'100vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    // for scrolling
    mt: 5,
    flexGrow: 1,
    display: 'flex', 
    flexDirection: 'column',
    gap: 2,
    overflow: 'hidden'
  };


  return (

      <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="add-grn-modal-title"
      aria-describedby="add-grn-modal-description"
    >
   <Box sx={modalStyle}>

   <Button
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        Close
      </Button>

    <Typography 
            sx={{ textAlign: 'center',
             marginBottom: 1,  
             fontWeight: 'bold'}} 
            variant="h6" 
            component="h4">
      Edit GRN
    </Typography>

    <Divider/>

    <TableContainer component={Paper} sx={{ maxHeight: '100%', overflow: 'auto' }}>
      <Table>
    <TableBody>
            <TableRow>

              <TableCell>PO Number</TableCell>
              <TableCell>
              <Tooltip title={data.po_number} arrow>
                <TextField disabled value={data.po_number} InputProps={{ readOnly: true }} fullWidth />
              </Tooltip>
              </TableCell>

              <TableCell>PO Date</TableCell>
              <TableCell>
                <TextField disabled value={data.purchased_date} InputProps={{ readOnly: true }} fullWidth />
              </TableCell>

                <TableCell>PO Rate</TableCell>
              <TableCell>
                <TextField disabled value={data.purchase_price} InputProps={{ readOnly: true }} fullWidth />
              </TableCell>

             
            
          {/* <TableCell>Supplier Name</TableCell>
          <TableCell>
      <FormControl fullWidth>
        <InputLabel id="supplier-select-label">Supplier</InputLabel>
        <Select
          disabled
          ref={supplierRef}
          labelId="supplier-select-label"
            id="supplier-select"
            // value={productEditObject.supplier_name || ''}
            value = {supplierName}
            label="Supplier"
            onChange={(event) => {
              const selectedSupplier = suppliersList.find(supplier => supplier.value === event.target.value);
                  if (selectedSupplier) {
                    setProductEditObject({
                      ...productEditObject,
                      supplier_name: selectedSupplier.value,
                      supplier_id: selectedSupplier.key,
                      supplier_email: selectedSupplier.email,
                      bill_address: selectedSupplier.address,
                      bill_city: selectedSupplier.city,
                      bill_state: selectedSupplier.state,
                      bill_street: selectedSupplier.street,
                      bill_pin: selectedSupplier.pin,
                      bill_fssai: selectedSupplier.fssai,
                      bill_gstin: selectedSupplier.gstin,
                      bill_phone: selectedSupplier.phone,
                    });
                  }
                }}
              >
                {suppliersList && suppliersList.map((supplier) => (
                  <MenuItem key={supplier.key} value={supplier.value}>
                    {supplier.value}
                  </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell> */}



            </TableRow>


            <TableRow>
                
                <TableCell>Source</TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <InputLabel>Source</InputLabel>
                  <Select
                    value={data?.source_type ? data.source_type.toUpperCase() : ""}
                    label="Source"
                    disabled
                  >
                    <MenuItem value="MILL">MILL</MenuItem>
                    <MenuItem value="LOCAL PICK">LOCAL PICK</MenuItem>
                    <MenuItem value="DC DELIVERED">DC DELIVERED</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>

              <TableCell>Article Code</TableCell>
              <TableCell>
                <TextField disabled value={data.article_code} InputProps={{ readOnly: true }} fullWidth />
              </TableCell>

              <TableCell>Item Name</TableCell>    
              <TableCell>
              <Tooltip title={data.item_name} arrow>
                <TextField disabled value={data.item_name} InputProps={{ readOnly: true }} fullWidth />
                </Tooltip>
              </TableCell>

            </TableRow>




            <TableRow>

            <TableCell>Category</TableCell>
              <TableCell>
                <TextField disabled value={data.category} InputProps={{ readOnly: true }} fullWidth />
              </TableCell>

              <TableCell>HSN</TableCell>
              <TableCell>
                <TextField disabled value={data.hsn_code} InputProps={{ readOnly: true }} fullWidth />
              </TableCell>

              <TableCell>Outer Type</TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <InputLabel>Outer Type</InputLabel>
                  <Select
                    value={data?.outer_type || ""}
                    label="Outer Type"
                    disabled
                  >
                    <MenuItem value="SMALL PACK">SMALL PACK</MenuItem>
                    <MenuItem value="GONI">GONI</MenuItem>
                    <MenuItem value="BAG OF PACKS">BAG OF PACKS</MenuItem>
                    <MenuItem value="HIGH VALUE OF LOOSE">HIGH VALUE OF LOOSE</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>

            </TableRow>




            <TableRow>

            <TableCell>Outer Size</TableCell>
              <TableCell>
                <TextField disabled value={`${data.outer_size} ${data?.uom || ""}`} InputProps={{ readOnly: true }} fullWidth />
              </TableCell>

              <TableCell>Bags Received *</TableCell>
              <TableCell>
                <TextField
                  inputRef={bagReceivedRef}
                  type="number"

                  error={!!errors.no_of_bags}
                  helperText={errors.no_of_bags}

                  defaultValue={data.bags_received}
                  onChange={(e) => {
                    const bagsReceived = parseInt(e.target.value, 10);
                    if (bagsReceived > data.bag_purchased) {
                      e.target.value = "";
                      toast("Bags Received cannot be more than Bag Purchased", {
                        type: toast.TYPE.ERROR,
                      });
                      return;
                    }
                    setProductEditObject({
                      ...productEditObject,
                      bags_received: e.target.value,
                    });

                    // Added
                    setPoAmount(parseFloat(e.target.value)*parseFloat(purchasePrice)*parseFloat(data.outer_size))

                    setBagRecieved(e.target.value);
                    setTotalWtReceived(Number(data.outer_size) * Number(e.target.value));
                    setProductEditObject({
                      ...productEditObject,
                      invoice_amt: Number(data.outer_size) * Number(e.target.value) * invoiceRatePer,
                    });
                  }}


                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#28a745',
                        borderWidth: 2,
                      },
                      '&:hover fieldset': {
                        borderColor: '#28a745',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#007bff',
                      },
                    },
                  }}

                  fullWidth

                />
              </TableCell>

               <TableCell>Difference in Receiving</TableCell>
              <TableCell>
                <TextField
                  inputRef={diffWtRef} // woah
                  value={bagRecieved ? Number(data.total_weight) - totalWtReceived : ""}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled
                />
              </TableCell>
             
            
            {/* 
              <TableCell>Material Receiving Date</TableCell>
              <TableCell>
                <TextField
                  type="date"
                  value={materialUiformatDate(materialReceivingDt)}
                  onChange={(e) => {
                    const newDate = e.target.value ? new Date(e.target.value) : null;
                    setMaterialReceivingDt(newDate);
                    setProductEditObject({
                      ...productEditObject,
                      grn_date: formatDate(newDate), // converts to "dd/MM/yyyy"
                    });
                  }}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled
                />
              </TableCell> */}

            


            </TableRow>



            
          <TableRow>

          <TableCell>Total Weight Ordered</TableCell>
              <TableCell>
                <TextField disabled value={`${data.total_weight} ${data?.uom || ""}`} InputProps={{ readOnly: true }} fullWidth />
              </TableCell>
        
              
              <TableCell style={{ whiteSpace: 'nowrap' }}><span>Purchase Price *</span></TableCell>
              <TableCell>
                <TextField
                  type="number"

                  error={!!errors.purchase_price}
                  helperText={errors.purchase_price}

                 // defaultValue={Number(data.invoice_amt) / totalWtReceived}
                  defaultValue = {data.purchase_price}
                  onChange={(e) => {
                    setInvoiceRatePer(e.target.value);

                    // Added
                    setPoAmount(parseFloat(e.target.value)*parseFloat(bagRecieved)*parseFloat(data.outer_size))

                    setPurchasePrice(e.target.value)
                    setProductEditObject({
                      ...productEditObject,
                      invoice_amt: Number(e.target.value) * Number(totalWtReceived),
                    });
                  }}
                  fullWidth

                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#28a745',
                        borderWidth: 2,
                      },
                      '&:hover fieldset': {
                        borderColor: '#28a745',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#007bff',
                      },
                    },
                  }}

                />
                </TableCell>

             
           

              
                <TableCell>GST Amount</TableCell>
              <TableCell>
                {/* <TextField disabled value={getGSTCharges()} InputProps={{ readOnly: true }} fullWidth /> */}
                <TextField disabled value={gstCharge} InputProps={{ readOnly: true }} fullWidth />
              </TableCell>
            

              </TableRow>



              <TableRow>

            
               <TableCell>APMC Amount</TableCell>
              <TableCell>
                {/* <TextField disabled value={getAPMCCharges()} InputProps={{ readOnly: true }} fullWidth /> */}
                <TextField disabled value={apmcCharge} InputProps={{ readOnly: true }} fullWidth />
              </TableCell>
          
              <TableCell>Final Invoice Rate (per kg/pc)</TableCell>
              <TableCell>
                <TextField
                  value={
                  //   (
                  //   (Number(productEditObject?.invoice_amt || data.invoice_amt) +
                  //     getGSTCharges() +
                  //     getAPMCCharges() +
                  //     getTransportCharges() -
                  //     Number(productEditObject?.scheme_amount || 0)) /
                  //   totalWtReceived
                  // ).toFixed(2)

                  totalWtReceived==0 ? 0 : (finalInvoiceCalculationField()/totalWtReceived).toFixed(2)
                
                }
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled
                />
              </TableCell>
             

                <TableCell>Invoice Amount</TableCell>
              <TableCell>
                <TextField
                 // value={productEditObject?.invoice_amt || data.invoice_amt}
                  value={poAmount}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled
                />
              </TableCell>

              </TableRow>





              <TableRow>

               

              <TableCell>Total Weight Received</TableCell>
              <TableCell>
                <TextField
                 // disabled
                  // inputRef={totalWtReceived}
                  value={totalWtReceived}
                  onChange={(e) => {
                    setTotalWtReceived(Number(e.target.value));
                    setProductEditObject({
                      ...productEditObject,
                      invoice_amt: Number(e.target.value) * invoiceRatePer,
                    });
                    setPoAmount(Number(e.target.value)*Number(purchasePrice))
                  }}
                  fullWidth
                />
              </TableCell>

             

              <TableCell>Scheme Amount</TableCell>
              <TableCell>
                <TextField
                  onChange={(e) =>
                  {
                    setProductEditObject({
                      ...productEditObject,
                      scheme_amount: e.target.value,
                    })
                    setSchemeAmount(e.target.value)
                  }
                  }
                  fullWidth
                />
              </TableCell>

                 <TableCell>Replacement Brand *</TableCell>
              <TableCell>
                <TextField
                  onChange={(e) => setReplaceBrand(e.target.value)}
                  defaultValue={data.replacement_brand}
                  error={!!errors.replacement_brand}
                  helperText={errors.replacement_brand}
                  fullWidth

                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#28a745',
                        borderWidth: 2,
                      },
                      '&:hover fieldset': {
                        borderColor: '#28a745',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#007bff',
                      },
                    },
                  }}

                />
              </TableCell>

                  
              </TableRow>





              {/* <TableRow> */}

              {/* <TableCell>Invoice Date</TableCell>
              <TableCell>
                <TextField
                  type="date"
                  value={materialUiformatDate(invoiceDate) || (data.invoice_date ? materialUiformatDate(data.invoice_date) : materialUiformatDate(new Date(data.invoice_date)))}
                  onChange={(e) => {
                    setProductEditObject({
                      ...productEditObject,
                      invoice_date: e.target.value,
                    });
                    setInvoiceDate(e.target.value);
                  }}
                  InputProps={{
                    inputProps: { 
                     // min: data.purchased_date
                     max :  materialUiformatDate(new Date())
                    }
                  }}
                  fullWidth
                />
              </TableCell> */}

           
             

           


              {/* </TableRow> */}




              <TableRow>

          
              {/* <TableCell>Invoice Number</TableCell>
              <TableCell>
                <Creatable
                  options={invoiceNoList}
                  placeholder={data.invoice_number}
                  onChange={(val) =>
                    setProductEditObject({
                      ...productEditObject,
                      invoice_number: val.value,
                    })
                  }
                  styles={{ container: (base) => ({ ...base, width: '100%' }) }}
                />
              </TableCell> */}

{/* <TableCell>Invoice Number</TableCell>
      <TableCell>
        <Autocomplete
         fullWidth
          options={invoiceNoList}
          getOptionLabel={(option) => option.label || ''}
          renderInput={(params) => (
            <TextField
           // style={{ width: '100%' }}
           // fullWidth
              {...params}
              required
              placeholder={data.invoice_number}
              variant="outlined"
            />
          )}
          onChange={(event, newValue) => {
            setProductEditObject({
              ...productEditObject,
              invoice_number: newValue ? newValue.value : '',
            });
          }}
          // freeSolo
          // style={{ width: "100px" }}
        />
      </TableCell> */}



<TableCell>Final Invoice Amount</TableCell>
              <TableCell>
                <TextField
                  value={

                    // Number(productEditObject?.invoice_amt || data.invoice_amt) +
                    // getGSTCharges() +
                    // getAPMCCharges() +
                    // getTransportCharges() -
                    // Number(productEditObject?.scheme_amount || 0)

                    (finalInvoiceCalculationField() || 0) .toFixed(2)
                  }
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled
                />
              </TableCell>

       


              <TableCell>Location</TableCell>
              <TableCell>
                <TextField
                  defaultValue={data.location}
                  onChange={(e) => {
                    setProductEditObject({
                      ...productEditObject,
                      location: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </TableCell>


              <TableCell>Remark</TableCell>
              <TableCell>
                <TextField
                  defaultValue={data.remark}
                  onChange={(e) =>
                    setProductEditObject({
                      ...productEditObject,
                      remark: e.target.value,
                    })
                  }
                  fullWidth
                />
              </TableCell>


              </TableRow>






              <TableRow>
             

              <TableCell>MRP</TableCell>
              <TableCell>
                <TextField
                  defaultValue={data.mrp}
                  onChange={(e) =>
                    setProductEditObject({
                      ...productEditObject,
                      mrp: e.target.value,
                    })
                  }
                  fullWidth
                />
              </TableCell>



              </TableRow>



            {/*For Dynamic Data */}
             {/* should create different tablerows for each dynamic render */}
            <TableRow>

            {data.category && data.category.toUpperCase() === "FRESH" && (
              <>
               
                  <TableCell>Batch No</TableCell>
                  <TableCell>
                    <TextField
                      value={data.batch_no}
                      onChange={(e) =>
                        setProductEditObject({
                          ...productEditObject,
                          batch_no: e.target.value,
                        })
                      }
                      fullWidth
                    />
                  </TableCell>
              
                  <TableCell>Expiry Date</TableCell>
                  <TableCell>
                    <TextField
                      type="date"
                      value={expiryDate || data.expiry_date || ''}
                      onChange={(e) => {
                        setExpiryDate(e.target.value);
                        setProductEditObject({
                          ...productEditObject,
                          expiry_date: e.target.value,
                        });
                      }}
                      InputProps={{
                        inputProps: { 
                          min: new Date().toISOString().split('T')[0]
                        }
                      }}
                      fullWidth
                    />
                  </TableCell>
              </>
            )}



          {data.transport && (
              <>
                <TableCell>Transport</TableCell>
                <TableCell>
                  <FormControl fullWidth>
                    <InputLabel>Transport</InputLabel>
                    <Select
                      value={data?.transport ? data.transport.toUpperCase() : ""}
                      label="Transport"
                      disabled
                    >
                      <MenuItem value="EXCLUDED">EXCLUDED</MenuItem>
                      <MenuItem value="INCLUDED">INCLUDED</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                </>
            )}


      {data.transport === "excluded" && (
              <>
             
                  <TableCell>Total Transport</TableCell>
                  <TableCell>
                    <TextField
                      placeholder={String(data.transport_charges * data.total_weight)}
                      onChange={(e) =>
                        setProductEditObject({
                          ...productEditObject,
                          total_transport: e.target.value,
                          transport_charges: Number(e.target.value) / Number(data.total_weight),
                        })
                      }
                      fullWidth
                    />
                  </TableCell>
               
              
                  <TableCell>Mill Transport (Per KG/Pc) *</TableCell>
                  <TableCell>
                    <TextField
                      value={productEditObject?.transport_charges || data.transport_charges}
                      InputProps={{ readOnly: true }}
                      fullWidth
                      disabled
                    />
                  </TableCell>
               
              </>
            )}

            </TableRow>


      </TableBody>
      </Table>
      </TableContainer>

    <Divider />
    <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
     <Button sx={{ mr: 1 }} onClick={() => createGrnNumber()} variant="contained" color="primary">
          Save
        </Button>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Close
        </Button>
    </Box>

   </Box>
    
    </Modal>

  );
}

export default EditGRNModal;
