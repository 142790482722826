import React, { useEffect, useState,useRef } from "react";
import { validateIsAdminUser } from "../../service/credentials";
import { LOGGED_USER_EMAIL } from "../../constants/allConstants";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField, 
  Button,
  Paper,
  IconButton,
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Stack,
  Toolbar,
  Box,
  Modal,Divider,Grid
} from "@mui/material";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { toast } from "react-toastify";
import axios from "axios";
import ReactToPrint from "react-to-print";
import QRCode from "react-qr-code";
import NavBar from "../../Dashboard/Navbar/Dashboard_Navabar";
import firebase from '../../service/firebaseConfig';
import baseUrl from '../../service/servicesConfig';
const userType = sessionStorage.getItem("userType") || "";


const RebaggingTable = () => {

  const userCity = sessionStorage.getItem("Logged User City") || "";
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setData] = useState({
    parentChildItems: [],
    repackedItems: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [ formData, setFormData] = useState({
    child_item: null,
    parent_name: "",
    qtyRebagged: "",
  });
  const [open, setOpen] = useState(false);
  const [userWarehouseList,setUserWarehouseList] = useState([]);
  // For QR code
  const [ProductItem, setProductItem] = useState({});
  const [BarCodeList, setBarCodeList] = useState([]);
  const [BarCodeSplitList, setBarCodeSplitList] = useState([]);
  const [QRshow, setQRShow] = useState(false);
  let printRef = useRef();

const handleOpenModal = () => setOpen(true);
const handleCloseModal = () => {
    setFormData({
      child_item: null,
      parent_name: "",
      qtyRebagged: "",
      warehouse:""
    });
    setOpen(false);
  };
  
  useEffect(() => {

    if (userCity && userCity.trim() !== "") {
      setUserWarehouseList([userCity]); }
      else{
    firebase.database().ref(`/Warehouses`).once('value').then((snapshot) => {
      if (snapshot.exists()) {
        const warehouses = snapshot.val(); 
        const dcWarehouses = Object.keys(warehouses).filter(
          (key) => warehouses[key].type === "dc"
        ); 
    
        setUserWarehouseList(dcWarehouses);
      } else {
        setUserWarehouseList([]);
      }
    });
  }
    

  },[])


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); 
        const formattedDate = selectedDate.toISOString().split('T')[0];
    
        const [parentChildResponse, repackedItemsResponse] = await Promise.allSettled([
          baseUrl.get(`/api/superzop/ordering/getParentChildItems`),
          baseUrl.get(`/api/superzop/commercial/getRebaggedItems?date_of_packing=${formattedDate}`)
        ]);
    
        if (parentChildResponse.status === "fulfilled" && repackedItemsResponse.status === "fulfilled") {
          setData({
            parentChildItems: parentChildResponse.value.data,
            repackedItems: repackedItemsResponse.value.data
          });
        } else {
          let errorMessage = "Failed to fetch data: ";
          if (parentChildResponse.status === "rejected") {
            errorMessage += "Parent-Child items API failed. ";
            console.log("Parent-Child API Error:", parentChildResponse.reason);
          }
          if (repackedItemsResponse.status === "rejected") {
            errorMessage += "Rebagged Items listing API failed. ";
            console.log("Rebagged Items API Error:", repackedItemsResponse.reason);
          }
    
          toast(errorMessage, { type: toast.TYPE.ERROR });
          setError(errorMessage);
        }
      } catch (err) {
        toast("Unexpected error occurred.", { type: toast.TYPE.ERROR });
        setError("Unexpected error occurred.");
        console.log("Unexpected API Error:", err);
      } finally {
        setLoading(false); 
      }
    };
    
  
    fetchData();
  }, []);

    
    const fetchRebaggingData = async (date) => {
        setLoading(true);
        try {
          const formattedDate = date.toISOString().split('T')[0]; 
          const repackedItemsResponse = await baseUrl.get(
            `/api/superzop/commercial/getRebaggedItems?date_of_packing=${formattedDate}`
          );
    
          setData((prevState) => ({
            ...prevState,
            repackedItems: repackedItemsResponse.data
          }));
        } catch (err) {
             toast("Failed to fetch rebagging data.", {
                        type: toast.TYPE.ERROR,
                      });
          setError("Failed to fetch rebagging data.");
          console.log("API Error:", err);
        } finally {
          setLoading(false);
        }
      };
    
  
   
   const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date) {
      fetchRebaggingData(date);
    }
  };

  if (loading) return <CircularProgress color="primary" sx={{ display: "block", margin: "auto", mt: 4 }} />;
  // if (error) return <Typography color="error">{error}</Typography>;

  const transformFormData = (formData) => {
    const [parentWeightValue, parentWeightUnit] = formData.child_item.parent_weight.split(' ');
    const [childWeightValue, childWeightUnit] = formData.child_item.child_weight.split(' ');
  
    const now = new Date();
    const timestamp = now.getTime();
    const formattedDate = now.toISOString().split('T');
    const date = formattedDate[0];  
    const time = formattedDate[1].split('.')[0]; 
    
    const apiData = {
      bom_id : `${formData.child_item.parent_item}-${formData.child_item.child_item}-${timestamp}`,
      parent_name: formData.child_item.parent_name,
      parent_code: formData.child_item.parent_item, 
      major_category: formData.child_item.major_category,  
      outer_size: parentWeightValue,    
      uom: parentWeightUnit,
      stock_transfer_total_weight: formData.qtyRebagged * parseFloat(parentWeightValue), 
      stock_transfer_kgs: formData.qtyRebagged * parseFloat(parentWeightValue), 
      stock_transfer: formData.qtyRebagged * parseFloat(childWeightValue) / parseFloat(parentWeightValue),
      child_stock_production: formData.qtyRebagged, 
      child_item_code: formData.child_item.child_item, 
      child_weight:formData.qtyRebagged * parseFloat(childWeightValue),
      child_outer_size: childWeightValue,
      child_item_name: formData.child_item.child_name,
      date_of_packing: `${date} ${time}`,
      stock_transfer_date: `${date} ${time}`,
      created_by:sessionStorage.getItem("Logged User Email Id"),
      warehouse:formData.warehouse || userCity
    };
  
    return apiData;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate mandatory fields
    if (!formData.child_item || !formData.parent_name || !formData.qtyRebagged || !formData.warehouse) {
        toast("Please fill in all mandatory fields.", { type: toast.TYPE.ERROR });
        return;
    }

    try {
        console.log("Form Submitted:", formData);

        // inventory API to check available stock
        const inventoryResponse = await baseUrl.get(`/inventory-picking`, {
            params: {
                inventory_date: new Date().toISOString().split("T")[0],
                item_id: formData.child_item.parent_item,
                city: formData.warehouse || userCity,
            },
        });

        if (!inventoryResponse || !inventoryResponse.data) {
            toast("Failed to get inventory details for parent", { type: toast.TYPE.ERROR });
            return;
        }

        const availableStock = inventoryResponse.data.data ?? 0; 

        if (parseInt(formData.qtyRebagged, 10) > availableStock) {
            toast(`Not enough stock available! Only ${availableStock} bags left for ${ formData.warehouse || userCity} `, { type: toast.TYPE.ERROR });
            return;
        }

        const rebaggingPayload = transformFormData(formData);
        const qrGenerationPayload = {
            grn_number: rebaggingPayload.bom_id,
            item_id: rebaggingPayload.child_item_code,
            grn_date: new Date().toISOString().split('T')[0],
            bags_received: rebaggingPayload.child_stock_production,
        };

        //  APIs for rebagging and QR generation
        const [rebaggingResponse, qrGenerationResponse] = await Promise.allSettled([
            baseUrl.post(`/api/superzop/commercial/insertRebaggedItem`, rebaggingPayload),
            baseUrl.post(`/api/bag-qr`, qrGenerationPayload)
        ]);

        const successMessages = [];
        const errorMessages = [];

        if (rebaggingResponse.status === "fulfilled") {
            const today = new Date().toISOString().split('T')[0];
            successMessages.push(`Rebagging data saved for ${today}`);
        } else {
            errorMessages.push(`Rebagging API failed: ${rebaggingResponse.reason.response?.data?.error || rebaggingResponse.reason.message}`);
        }

        if (qrGenerationResponse.status === "fulfilled") {
            successMessages.push("QR generated successfully.");
        } else {
            errorMessages.push(`QR generation API failed: ${qrGenerationResponse.reason.response?.data?.error || qrGenerationResponse.reason.message}`);
        }

        if (successMessages.length > 0) {
            toast(successMessages.join("\n"), { type: toast.TYPE.SUCCESS });
        }

        if (errorMessages.length > 0) {
            toast(errorMessages.join("\n"), { type: toast.TYPE.ERROR });
        }

        if (successMessages.length > 0) {
            resetFormAndFetchData(selectedDate);
        }

    } catch (err) {
        console.log("Unexpected Error:", err);
        toast(`error occurred while fetching inventory data: ${err.message || err}`, { type: toast.TYPE.ERROR });
    }
};




  const handleDelete = async (rowID) => {
   
    const confirmDelete = window.confirm("Are you sure you want to delete this item? This action cannot be undone.");

    if (!confirmDelete) {
        return; 
    }

    try {
      const response = await baseUrl.delete(
        `/api/superzop/commercial/deleteRebaggedItem?id=${rowID}`
      );
  
      if (response.status === 200) {
        toast("Item deleted successfully!", {
          type: toast.TYPE.SUCCESS,
        });
        resetFormAndFetchData(selectedDate);
      } else {
        toast("Failed to delete the item.", {
          type: toast.TYPE.ERROR,
        });
      }
    } catch (err) {
      console.error("API Error:", err);
  
      const errorMessage =
        err.response?.data?.error || "Something went wrong. Please try again.";
  
      toast(errorMessage, {
        type: toast.TYPE.ERROR,
      });
    }
  };
  
  
  const handleChildSelection = (event, value) => {
    if (value) {
      setFormData({
        ...formData,
        child_item: value,
        parent_name: value.parent_item ? `${value.parent_item} -${value.parent_brand} -${value.parent_name} - ${value.parent_weight}` : "",
      });
    } else {
      setFormData({
        ...formData,
        child_item: null,
        parent_name: "",
      });
    }
  };


  const resetFormAndFetchData = async (date) => {
    try {
      await fetchRebaggingData(date);
      
      setFormData({
        child_item: null,
        parent_name: "",
        qtyRebagged: "",
      });
      setOpen(false);
    } catch (err) {
      toast("An error occurred while resetting form and fetching data.", {
        type: toast.TYPE.ERROR,
      });
    }
  };

  const handlePrintQR = async (item) => {
    if (!item.bom_id) {
      return toast("Error: Unable to fetch QR codes, GRN No not found.", {
        type: toast.TYPE.ERROR,
      });
    }
  
    let toast_id = toast("Fetching data, Please wait", {
      type: toast.TYPE.SUCCESS,
      autoClose: false,
    });
  
    try {
      const response = await baseUrl.get(`/api/bag-qr`, {
        params: {
          grn_number: item.bom_id,
          item_id: item.child_item_code,
        },
      });
  
      let qr_list = response.data.data.map((qrItem) => qrItem.qr_id);
  
      let obj = { ...item, new_item_name: item?.child_item_name };
  
      setProductItem(obj);
      setBarCodeList(qr_list);
      setBarCodeSplitList(qr_list);
      setQRShow(true);
    } catch (error) {
      console.error("Error occurred while fetching QR IDs:", error.message);
      toast(`Error occurred while fetching QR IDs, ${error.message}`, {
        type: toast.TYPE.ERROR,
      });
    } finally {
      toast.dismiss(toast_id);
    }
  };
  

  const handleQRclose = () => setQRShow(false);

  return (
    <>
      <NavBar />
      <Toolbar />
      <Container sx={{  paddingTop: "1rem",width: '100vw', maxWidth: '100% !important', }}>

      <Stack alignItems="center" spacing={3}>
       

      <Stack
  direction="row"
  alignItems="center"
  justifyContent="flex-start"
  spacing={2}
  sx={{ width: "100%", padding: "8px 0" }}
>
  <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
    Rebagging
  </Typography>

  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
    Select Date:
  </Typography>

  <DatePicker
    selected={selectedDate}
    onChange={handleDateChange}
    maxDate={new Date()}
    dateFormat="yyyy-MM-dd"
    placeholderText="Select a date"
    customInput={
      <TextField
        size="small"
        variant="outlined"
        sx={{ minWidth: 180, backgroundColor: "white" }}
      />
    }
  />

  <Button variant="contained" sx={{ whiteSpace: "nowrap" }} onClick={handleOpenModal}>
    Create Rebagging
  </Button>
</Stack>

</Stack>

<TableContainer component={Paper}  sx={{ mt: 2, width: '100%'}}>
<Table sx={{
    '& td, & th': { 
      textAlign: 'center'
    }
  }}>
  <TableHead >
    <TableRow>
      
      <TableCell><strong>Date</strong></TableCell>
      <TableCell><strong>Parent Code</strong></TableCell>
      <TableCell><strong>Parent Name</strong></TableCell>
      <TableCell><strong>Outer Size</strong></TableCell>
      <TableCell><strong>Child Code</strong></TableCell>
      <TableCell><strong>Child Name</strong></TableCell>
      <TableCell  sx={{ whiteSpace: 'pre-line' }}><strong>
      <span style={{ display: 'block' }}>Child</span>
      <span style={{ display: 'block' }}>Outer Size</span></strong></TableCell>
      <TableCell><strong>
        <span style={{ display: 'block' }}>Stock Rebagged</span>
        <span style={{ display: 'block' }}>(Bags)</span>
        </strong>
      </TableCell>

      <TableCell><strong>Warehouse</strong></TableCell>
      <TableCell><strong>Created By</strong></TableCell>
      <TableCell><strong>Print QR</strong></TableCell>
      {validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && <TableCell><strong>Delete</strong></TableCell>}
    </TableRow>
  </TableHead>
  <TableBody>
  {data.repackedItems && data.repackedItems.length > 0 ? (
  (() => {
    const filteredItems = validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL))
      ? data.repackedItems  
      : data.repackedItems.filter((item) => item.warehouse.toLowerCase() === userCity.toLowerCase());

    return filteredItems.length > 0 ? (
      filteredItems.map((item,index) => {
        const utcDate = new Date(item.date_of_packing); 
        const istDate = new Date(utcDate.getTime() + (5.5 * 60 * 60 * 1000)); 
        
        const dateIST = istDate.toISOString().split("T")[0]; 
        const fullTimeIST = istDate.toTimeString().split(" ")[0]
         

        return (
          <TableRow key={item.bom_id}  sx={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white' }}>
           <TableCell  sx={{ width: 'auto', whiteSpace: 'nowrap' }} >
              <div>{dateIST}</div> 
              <div>{fullTimeIST}</div> 
           </TableCell>
            <TableCell>{item.parent_code}</TableCell>
            <TableCell>{item.parent_name}</TableCell>
            <TableCell>{item.parent_outer_size} {item.uom}</TableCell>
            <TableCell>{item.child_item_code}</TableCell>
            <TableCell>{item.child_item_name}</TableCell>
            <TableCell>{item.child_outer_size} {item.uom}</TableCell>
            <TableCell>{item.child_stock_production}</TableCell>
            <TableCell>{item.warehouse}</TableCell>
            <TableCell>{item.created_by}</TableCell>
            <TableCell>
              <IconButton color="primary" onClick={() => handlePrintQR(item)}>
                <i className="fas fa-print fa-1x" style={{ color: "blue", cursor: "pointer" }}></i>
              </IconButton>
            </TableCell>

            {validateIsAdminUser(sessionStorage.getItem(LOGGED_USER_EMAIL)) && (
              <TableCell>
                <IconButton color="error" onClick={() => handleDelete(item.bom_id)}>
                  <i className="fas fa-trash-alt fa-1x" style={{ color: "red", cursor: "pointer" }}></i>
                </IconButton>
              </TableCell>
            )}
          </TableRow>
        );
      })
    ) : (
      <TableRow>
        <TableCell colSpan={12} style={{ textAlign: "center" }}>
          No rebagging data available for <strong>{userCity}</strong> on the selected date.
        </TableCell>
      </TableRow>
    );
  })()
) : (
  <TableRow>
    <TableCell colSpan={12} style={{ textAlign: "center" }}>
      No rebagging data available for the selected date.
    </TableCell>
  </TableRow>
)}


  </TableBody>
</Table>
</TableContainer>
      </Container>

      {/* modals */}
      <Dialog open={open} onClose={handleCloseModal} maxWidth="sm" fullWidth>
  <DialogTitle>Create Rebagging</DialogTitle>
  <DialogContent>
    <form onSubmit={handleSubmit}>
      <Autocomplete
        options={data.parentChildItems}
        getOptionLabel={(option) => `${option.child_item} -${option.child_brand} -${option.child_name} - ${option.child_weight}`}
        onChange={handleChildSelection}
        value={formData.child_item}
        sx={{ mt: 2 }}
        renderInput={(params) => (
          <TextField {...params} label="Child Item" required fullWidth  inputProps={{ ...params.inputProps, style: { width: 'auto' } }}  />
        )}
      />

      <TextField
        label="Parent Name"
        value={formData.parent_name}
        sx={{ mt: 2 }}
        fullWidth
        InputProps={{
          readOnly: true,
          style: { width: 'auto', backgroundColor: '#f5f5f5' }
        }}
      />

      <TextField
        label="Qty to be Rebagged"
        name="qtyRebagged"
        type="number"
        fullWidth
        required
        margin="normal"
        value={formData.qtyRebagged}
        sx={{ mt: 2 }}
        onChange={(e) => setFormData({ ...formData, qtyRebagged: e.target.value })}
        inputProps={{ style: { width: 'auto' } }}
      />

{(
  <Autocomplete
  options={userWarehouseList}
  onChange={(event, newValue) => setFormData({ ...formData, warehouse: newValue })}
  value={formData.warehouse || (userWarehouseList.length === 1 ? userWarehouseList[0] : "")}
  disableClearable={userWarehouseList.length === 1} 
  readOnly={userWarehouseList.length === 1} 
  renderInput={(params) => (
    <TextField
      {...params}
      label="Select Warehouse"
      required
      fullWidth
      InputProps={{
        ...params.InputProps,
        readOnly: userWarehouseList.length === 1, 
      }}
    />
  )}
  sx={{ mt: 2 }}
/>


)}



      <DialogActions>
        <Button onClick={handleCloseModal} color="secondary">Cancel</Button>
        <Button type="submit" variant="contained" color="primary">Submit</Button>
      </DialogActions>
    </form>
  </DialogContent>
</Dialog>

<Modal
      open={QRshow}
      onClose={handleQRclose}
      aria-labelledby="print-qr-code-modal"
      aria-describedby="modal-to-print-qr-codes"
    >
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography id="print-qr-code-modal" variant="h6" component="h2">
            Print QR Code
          </Typography>
          
          <style type="text/css" media="print">
            {`@page { size: 50mm 25mm; orientation: portrait; }`}
          </style>
          
          <ReactToPrint
            trigger={() => <Button variant="contained" color="primary" sx={{ mt: 1 }}>Print</Button>}
            content={() => printRef.current}
          />
        </Box>

        <Divider />

        <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 1 }}>
          <Box ref={printRef}>
            {BarCodeList && BarCodeList.map((item, i) => (
              <Paper key={i} elevation={3} sx={{ mb: 4, p: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <QRCode
                      title="rebagging"
                      value={item}
                      size={500}
                      style={{
                        height: "auto",
                        maxWidth: "70%",
                        width: "auto",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                      {ProductItem?.new_item_name?.toUpperCase()}
                    </Typography>
                    <Typography variant="h5" sx={{ mt: 2 }}>
                      {BarCodeSplitList[i]}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Box>
        </Box>

        <Divider />

        <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleQRclose} sx={{ mr: 1 }}>
            Close
          </Button>
          <ReactToPrint
            trigger={() => <Button variant="contained" color="primary">Print</Button>}
            content={() => printRef.current}
          />
        </Box>
      </Paper>
    </Modal>

    </>
  );
};

export default RebaggingTable;
