import React from "react";
import HomePage from "./pages/ProductsPage";
import GRTPage from "./pages/GrtProducts/grtProducts";
import GrtList from "./pages/GrtPage/grtlist";
import LoginPage from "./pages/LoginPage";
import AddProductPage from "./pages/AddProductPage";
import BulkUploadsPage from "./pages/BulkOperationsPage";
import Dashboard from "./Dashboard/Home_page";
import ProtectedRoute from "./ProtectedRoute";
import CustomPortalIndex from "./Custom_Portal/components/login/Login";
import CustomUpdates from "./Custom_Portal/components/update/CustomUpdates";
import FileReader from "./Custom_Portal/components/filereader/FileReader";
import CreateNewRetailer from "./Custom_Portal/components/CreateRetailer/CreateNewRetailer";
import CreateNotifications from "./Custom_Portal/components/Notifications/CreateNotifications";
import SeriesListing from "./Custom_Portal/components/Notifications/SeriesListing";
import ApartmentDetails from "./Custom_Portal/components/Apartments_Blocks_Flats/ApartmentDetails";
import StockEdit from "./components/ProductDataCard/EditStock";
import PurchaseDetails from "./components/Purchase_Details/Add_Purchase";
import DamagedQrCode from "./components/DamagedQrCode/index";
import RTVDetails from "./components/Purchase_Details/RtvList";
import PurchaseList from "./components/Purchase_Details/ListPurchase";
import PurchaseReports from "./components/Purchase_Details/PurchaseReport";
import Popular from "./components/Popular";
import AddProductDump from "./components/ProductDump/AddProductDump";
import ProductDumpList from "./components/ProductDump/ProductDumpListing";
import ProductDumpReport from "./components/ProductDump/ProductDumpReport";
import AddStockTakeDetails from "./components/StockTake/AddStockTake";
import EditStockTake from "./components/StockTake/EditStockTake";
import ListStockTake from "./components/StockTake/ListStockTake";
import InventoryListing from "./components/SystemInventory/InventoryListing";
import PurchaseOrder from "./components/PurchaseOrder/PurchaseOrder";
import StockTakeReport from "./components/StockTake/StockTakeReport";
import UploadStockTake from "./components/StockTake/UploadStockTake";
import FinancialBillListing from "./components/FinanceListing/financelisting";
import StockConversion from "./components/StockConversion/StockConversionMain";
import StockConversionListing from "./components/StockConversion/StockConversionListing";
import StockConversionDetails from "./components/StockConversion/StockConversionDetails";
import AddStockConversion from "./components/StockConversion/AddNewStock";
import StockReport from "./components/StockConversion/StockConversionReport";
// import OffersMasterMain from "./components/OfferMaster/OfferMasterMain";
import AppHomeScreenConfig from "./pages/AppHomeScreenConfig";
import OffersMasterMainNew from "./components/OfferMaster/OfferMasterMainNew";
import OffersListing from "./components/OfferMaster/OfferListing/OffersListingNew";
import RetailerGroups from "./components/OfferMaster/retailerGroups";
import ConvertedList from "./components/StockConversion/ConvertedStock";
import inventoryListItems from "./components/SystemInventory/InventoryItems";
import inventoryAnalysis from "./components/SystemInventory/InventoryAnalysis";
import GRTAnalysis from "./pages/GrtPage/GrtAnalysis";
import GrtAnalysisList from "./pages/GrtPage/grtanalysislist";
import PriceAnalysis from "./pages/PriceAnalysis/priceanalysis";
import PriceUpdates from "./pages/PriceUpdates/priceupdates";
import PriceUpdatesHistory from "./pages/PriceUpdatesHistory/priceupdateshistory";
import PriceLists from "./pages/PriceLists/priceList";
import modernTrade from "./pages/ModernTrade/modernTrade";
import WorkOrder from "./pages/WorkOrder/WorkOrder";
import ManagePortal from "./components/ManagePortal";
import UserSettings from "./components/UserSettings";
import AddSupplier from "./components/Purchase_Details/AddSupplier";
import SampleEntry from "./pages/SampleEntry/SampleEntry";



import { Switch, HashRouter, Route, Redirect } from "react-router-dom";
import Pending_Price_Updates from "./components/ProductData/Pending_Price_Updates";
import Invoices from "./components/Purchase_Details/invoices";
import { BillOfMaterials } from "./pages/BillOfMaterials";
import RebaggingPage from "./pages/RebaggingPage/rebagging";

const RootRoute = (props) => {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/Dashboard" component={Dashboard} />
        <ProtectedRoute exact path="/SampleEntry" component={SampleEntry} />
        <ProtectedRoute exact path="/custom_portal_index" component={CustomPortalIndex} />
        <ProtectedRoute exact path="/home" component={HomePage} />
        <ProtectedRoute exact path="/grtproducts" component={GRTPage} />
        <ProtectedRoute exact path="/grt" component={GrtList} />
        <ProtectedRoute exact path="/addProd" component={AddProductPage} />
        <ProtectedRoute exact path="/bulk" component={BulkUploadsPage} />
        <ProtectedRoute exact path="/stockEdit" component={StockEdit} />
        <ProtectedRoute exact path="/billOfMaterials" component={BillOfMaterials} />

        <ProtectedRoute
          exact
          path="/addPurchaseDetails"
          component={PurchaseDetails}
        />
        <ProtectedRoute
          exact
          path="/damagedQr"
          component={DamagedQrCode}
        />
        <ProtectedRoute
          exact
          path="/purchaseReport"
          component={PurchaseReports}
        />
        <ProtectedRoute exact path="/listpurchase" component={PurchaseList} />
        <ProtectedRoute
          exact
          path="/addProductDump"
          component={AddProductDump}
        />
        <ProtectedRoute
          exact
          path="/productDumpList"
          component={ProductDumpList}
        />
        <ProtectedRoute
          exact
          path="/productDumpReport"
          component={ProductDumpReport}
        />
        <ProtectedRoute
          exact
          path="/addStockTake"
          component={AddStockTakeDetails}
        />
        <ProtectedRoute exact path="/editStockTake" component={EditStockTake} />
        <ProtectedRoute exact path="/listStockTake" component={ListStockTake} />
        <ProtectedRoute
          exact
          path="/stockTakeReport"
          component={StockTakeReport}
        />
        <ProtectedRoute
          exact
          path="/uploadstocktake"
          component={UploadStockTake}
        />
        <ProtectedRoute
          exact
          path="/financialBillListing"
          component={FinancialBillListing}
        />
        <ProtectedRoute
          exact
          path="/inventoryListing"
          component={InventoryListing}
        />
        <ProtectedRoute exact path="/purchaseorder" component={PurchaseOrder} />
        <ProtectedRoute
          exact
          path="/custom_portal_index_update"
          component={CustomUpdates}
        />
        <ProtectedRoute
          exact
          path="/custom_portal_index_filereader"
          component={FileReader}
        />
        <ProtectedRoute
          exact
          path="/custom_portal_index_newRetailer"
          component={CreateNewRetailer}
        />
        <ProtectedRoute
          exact
          path="/create_notifications"
          component={CreateNotifications}
        />
        <ProtectedRoute
          exact
          path="/series_listing"
          component={SeriesListing}
        />
        <ProtectedRoute
          exact
          path="/custom_portal_index_apartmentsDetails"
          component={ApartmentDetails}
        />
        <ProtectedRoute
          exact
          path="/stockConversion"
          component={StockConversion}
        />
        <ProtectedRoute
          exact
          path="/stockConversionListing"
          component={StockConversionListing}
        />
        <ProtectedRoute
          exact
          path="/stockconversiondetails/:parent_code"
          component={StockConversionDetails}
        />
        <ProtectedRoute
          exact
          path="/addStockConversion"
          component={AddStockConversion}
        />
        <ProtectedRoute
          exact
          path="/stockConversionReport"
          component={StockReport}
        />
        
        <ProtectedRoute path="/offersMaster" component={OffersMasterMainNew} />
        <ProtectedRoute exact path="/offersListing" component={OffersListing} />
        <ProtectedRoute
          exact
          path="/retailerGroups"
          component={RetailerGroups}
        />

        <ProtectedRoute exact path="/convertedList" component={ConvertedList} />
        <ProtectedRoute
          exact
          path="/inventoryListItems"
          component={inventoryListItems}
        />
        <ProtectedRoute
          exact
          path="/inventoryAnalysis"
          component={inventoryAnalysis}
        />
        <ProtectedRoute
          exact
          path="/grtProductsAnalysis"
          component={GrtAnalysisList}
        />
        <ProtectedRoute exact path="/priceanalysis" component={PriceAnalysis} />
        <ProtectedRoute exact path="/priceupdates" component={PriceUpdates} />
        <ProtectedRoute
          exact
          path="/priceupdatehistory"
          component={PriceUpdatesHistory}
        />
        <ProtectedRoute exact path="/priceList" component={PriceLists} />
        <ProtectedRoute exact path="/mtPrice" component={modernTrade} />
        <ProtectedRoute exact path="/workOrder" component={WorkOrder} />
        <ProtectedRoute exact path="/rebagging" component={RebaggingPage} />
        <ProtectedRoute exact path="/manageUsers" component={ManagePortal} />
        <ProtectedRoute exact path="/settings" component={UserSettings} />
        <ProtectedRoute exact path="/addsupplier" component={AddSupplier} />
        <ProtectedRoute
          exact
          path="/apphomescreenconfig"
          component={AppHomeScreenConfig}
        />
        <ProtectedRoute exact path="/popular" component={Popular} />
        <ProtectedRoute
          exact
          path="/pending-price-updates"
          component={Pending_Price_Updates}
        />
        <ProtectedRoute exact path="/invoices" component={Invoices} />
        <ProtectedRoute exact path = "/rtvdetails" component = {RTVDetails} />
        
        <Redirect exact from="/" to="/login" />
      </Switch>
    </HashRouter>
  );
};

export default RootRoute;
