import React, { useEffect, useRef, useState } from "react";
import { Modal, Table, Form, Button, Dropdown, DropdownButton} from "react-bootstrap";
import { deliveryCommercialServer } from '../../service/servicesConfig';
import { toast } from 'react-toastify';
import baseUrl from '../../service/servicesConfig';
import { LOGGED_USER_TYPE,WITHOUT_WAREHOUSE_USER_ACCESS} from "../../constants/allConstants";

const selectedDate = new Date(sessionStorage.getItem("datekey"))


const ListGRNOpenModal = (props) => {

    const [grnOpenList, setGrnOpenList] = useState([]);
    const [modalTitle, setModalTitle] = useState("Open GRN Dates: ");
    const [cbGRNCloseList, setCbGRNCloseList] = useState({});
    const [formDatesList, setFormDatesList] = useState({}); // list of dates object mapped to date in yyyy-mm-dd format

    let btnRef = useRef();

    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_LEFT,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const today = new Date();
                const last30days = new Date();
                last30days.setDate(today.getDate() - 30);
    
                // Format dates to ensure two-digit months and dates
                const formatDate = (date) => {
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two digits
                    const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
                    return `${year}-${month}-${day}`;
                };
    
                const today_comp_date = formatDate(today);
                const last30days_comp_date = formatDate(last30days);
    
                const url = `/api/superzop/commercial/purchasedetails/getgrncloserecordbasedongrndate?grn_start_date=${last30days_comp_date}&grn_end_date=${today_comp_date}&warehouse=${props.selectedWarehouse}`;
    
                const response = await deliveryCommercialServer.get(url);
                if (response.status !== 200) {
                    throw new Error(`API request failed with status ${response.status}`);
                }
    
                const data = response.data;
    
                // Extract GRN close dates
                const grnCloseDates = data
                    .filter((item) => item.grn_entry_close === 1)
                    .map((item) => item.grn_date.substring(0, 10));
    
                // Generate open GRN dates for the last 30 days
                const grnOpenDates = [];
                const fromDates = {};
    
                for (let i = 0; i <= 30; i++) {
                    const date = new Date();
                    date.setDate(date.getDate() - i);
                    const dateString = formatDate(date); // Format as YYYY-MM-DD
                    const dateFormated = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }).replace(' ', '-'); // Format as "DD-MMM"
    
                    if (!grnCloseDates.includes(dateString)) {
                        grnOpenDates.push(dateFormated);
                    }
    
                    fromDates[dateFormated] = dateString;
                }
    
                // Update state
                setFormDatesList(fromDates);
                setGrnOpenList(grnOpenDates);
                setModalTitle(`Open GRN Dates: ${grnOpenDates.length} Open For ${props.selectedWarehouse}`);
            } catch (error) {
                console.error('Error fetching GRN data:', error);
                // Optionally, update state to show an error message to the user
            }
        };
    
        fetchData();
    }, [props.selectedWarehouse]);

    const closeGRNSubmit = () => {

        

        const today = new Date();
        const date = `${today.getFullYear()}-${(today.getMonth() + 1) < 10 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1}-${today.getDate() < 10 ? "0" + today.getDate() : today.getDate()}`;
        const time = `${today.getHours() < 10 ? "0" + today.getHours() : today.getHours()}:${today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes()}:${today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds()}`;

        const dateTime = date + ' ' + time;

        console.log(" closeGRNSubmit ", cbGRNCloseList)

        if (Object.keys(cbGRNCloseList).length === 0) {

            return toast("Please Select Dates to Close GRN", { type: toast.TYPE.ERROR });
        }

        // for(let obj of props.filterList)
        // {
          //if(obj.quality_approved===1 && (obj.location==="" || (obj.location ?? null)===null))
          if(!props.locationValidationFor_canGRNclose)
          {
            // Only checks aacording to the current selected date of the purchase and GRN
            return window.alert(`Please ensure inventory location is updated for all approved items, check PO number in GRN Date : ${props.checkPO_Number} for ${props.selectedWarehouse} warehouse`) //obj.po_number
          }
        // }
        
        for (let key in cbGRNCloseList) {
            if (cbGRNCloseList.hasOwnProperty(key)) {
                console.log(key + " -> " + cbGRNCloseList[key] + formDatesList[key]);

                const jsonObj = {
                    "grn_date": formDatesList[key],
                    "grn_entry_close": cbGRNCloseList[key],
                    "updated_by": "Admin",
                    "updated_at": dateTime,
                    "warehouse":props.selectedWarehouse
                }

                const url = "/api/superzop/commercial/purchasedetails/creategrnentrycloserecord";

                //baseUrl.post(url, jsonObj).then(res => {
                baseUrl.post(url, jsonObj).then(res => {

                    //console.log('closeGRNEntry ', res);
                    if (res.status === 200) {

                        toast(`Closed GRN for ${formDatesList[key]} date successfully`, { type: toast.TYPE.SUCCESS });
                        //return null;
                    }
                }).catch((error) => {

                    console.log(error);
                });
            }
        }

        setTimeout(() => {
            window.location.reload()
        }, 3000);
    }

    const handleChange = (event) => {

        console.log(" handleChange ", event.target.checked);
        console.log(" handleChange name: ", event.target.name);
        const obj = {
            ...cbGRNCloseList,
        }

        if (event.target.checked)
            obj[event.target.name] = event.target.checked;
        else {

            delete obj[event.target.name];
        }

        setCbGRNCloseList(obj);
    }

    return (
        <Form >
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {modalTitle}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>

                    {/* <DropdownButton
                    id="dropdown-basic-button"
                    title={props.selectedWarehouse
                    || "Select Warehouse"}
                // variant={variant}
                    onSelect={(e) => { props.setSelectedWarehouse(e) }}
                    disabled={props.avalilableDC_warehouse.length === 0}
                >
                    {props.avalilableDC_warehouse.map((item, index) => (
                    <Dropdown.Item
                        key={index}
                        eventKey={item.value || item}
                        href={item.href || '#'}
                    >
                        {item.label || item}
                    </Dropdown.Item>
                    ))}
                </DropdownButton>
                    <br/> */}

                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <td>Date</td>
                                </tr>
                            </thead>
                            <tbody>
                                {grnOpenList &&
                                    Object.keys(grnOpenList).map((items, index) => {

                                        const itemDate = new Date(grnOpenList[items]);  

                                        const isSelectedDate = itemDate.getMonth() === selectedDate.getMonth() &&  itemDate.getDate() === selectedDate.getDate();
                                        // console.log("selected Date",isSelectedDate)
                                        // debugger
                                        
                                        
                                        return (
                                        <>
                                            <tr>
                                                <td>
                                                    <Form.Check
                                                        inline
                                                        type="checkbox"
                                                        onChange={handleChange}
                                                        name={grnOpenList[items]}
                                                        label={grnOpenList[items]}
                                                        id={`inline-${index}`}
                                                        disabled={!isSelectedDate}
                                                         />
                                                </td>
                                            </tr>
                                        </>
                                        )
                                    })}
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" type="submit" onClick={() => { closeGRNSubmit() }} ref={btnRef}>Close GRN</Button>
                </Modal.Footer>

            </Modal>
        </Form>
    )
}

export default ListGRNOpenModal;