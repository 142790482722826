import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import baseUrl from "../../service/servicesConfig";

// Material UI imports
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography, 
  Box, 
  IconButton, 
  LinearProgress, 
  Paper, 
  Alert,
  AlertTitle
} from '@mui/material';
// Inline Close Icon (SVG)
const CloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
      <path d="M19.71 4.29a1 1 0 0 0-1.42 0L12 9.58 5.71 3.29a1 1 0 0 0-1.42 1.42L10.58 12 4.29 18.71a1 1 0 1 0 1.42 1.42L12 14.42l6.29 6.29a1 1 0 0 0 1.42-1.42L13.42 12l6.29-6.29a1 1 0 0 0 0-1.42z" />
    </svg>
  );
  
  // Inline Cloud Upload Icon (SVG)
  const CloudUploadIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
      <path d="M19 15c0 1.1-.9 2-2 2H7c-1.1 0-2-.9-2-2V9c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2v6zM12 3v7h-2V3H4l7-7l7 7h-6z" />
    </svg>
  );
  
  // Inline Delete Icon (SVG)
  const DeleteIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
      <path d="M16 9v10H8V9h8zm1-5h-2.5l-1-1h-5l-1 1H7l-1 1v1h12V5l-1-1z" />
    </svg>
  );
  

// Custom theme colors
const colors = {
  amber: '#ffc107',      // Primary - Amber
  purple: '#6610f2',     // Secondary - Purple
  green: '#28a745',      // Success - Green
  darkGray: '#343a40',   // Dark text/backgrounds
  white: '#fff'          // White
};

const ImageUploadModal = ({ open, onClose, setInvoiceImageRef, invoiceDate ,poNumber}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);


  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    
    if (file) {
      setSelectedFile(file);
      
      // Create preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
      
      // Reset states
      setUploadComplete(false);
      setUploadError(null);
    }
  };

  const uploadImageWithDateFolders = async (imageFile) => {
    try {
      setIsUploading(true);
      setUploadProgress(10);
      let year, month, day, dateString;
      // Get current date and format it
      if(invoiceDate){
        const now = new Date(invoiceDate);
        year = now.getFullYear().toString();
        month = (now.getMonth() + 1).toString().padStart(2, '0');
        day = now.getDate().toString().padStart(2, '0');
        dateString = `${year}-${month}-${day}`;

      }
      else{
        const now = new Date();
        year = now.getFullYear().toString();
        month = (now.getMonth() + 1).toString().padStart(2, '0');
        day = now.getDate().toString().padStart(2, '0');
        dateString = `${year}-${month}-${day}`;
      }
      
      // Create folder path structure: year/month/yyyy-mm-dd/
      const folderPath = `PO_Invoices/${year}/${month}/${dateString}`;
      
      setUploadProgress(30);
      
      // Create full storage path
      const storagePath = `${folderPath}/${poNumber}.jpg`;
      
      // Get references to Firebase services
      const storage = firebase.storage();
      const db = firebase.firestore();
      
      // Create a reference to the storage location
      const storageRef = storage.ref().child(storagePath);
      
      setUploadProgress(50);
      
      // Upload the file
      const snapshot = await storageRef.put(imageFile);
      // console.log('File uploaded successfully');
      
      setUploadProgress(70);
      
      // Get the download URL
      const downloadURL = await snapshot.ref.getDownloadURL();
      // console.log('File available at:', downloadURL);
    
      setInvoiceImageRef(downloadURL);
    
      setUploadProgress(100);
   
      setUploadComplete(true);
      setIsUploading(false);
      
      return downloadURL;
    } catch (error) {
      console.error('Error uploading image:', error);
      setUploadError(error.message);
      setIsUploading(false);
      throw error;
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setUploadError("Please select a file first");
      return;
    }
    
    try {
      await uploadImageWithDateFolders(selectedFile);
      // Auto-close modal after successful upload (optional)
      setTimeout(() => {
        if (onClose) onClose();
      }, 2000);
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    setUploadComplete(false);
    setUploadError(null);
    onClose();
  };

  const resetSelection = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    setUploadComplete(false);
    setUploadError(null);
  };

  return (
    <Dialog 
      open={open} 
      onClose={isUploading ? undefined : handleCancel}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: 3
        }
      }}
    >
      <DialogTitle sx={{ bgcolor: colors.darkGray, color: colors.white, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Upload Invoice Image</Typography>
        <IconButton 
          edge="end" 
          color="inherit" 
          onClick={handleCancel}
          disabled={isUploading}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent dividers sx={{ p: 3 }}>
        <Typography variant="subtitle1" gutterBottom sx={{ color: colors.darkGray, mb: 2 }}>
          Select Image
        </Typography>
        
        {previewUrl ? (
          <Box sx={{ mb: 3 }}>
            <Paper 
              elevation={2} 
              sx={{ 
                borderRadius: 2, 
                overflow: 'hidden',
                height: 200,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
              }}
            >
              <img 
                src={previewUrl} 
                alt="Preview" 
                style={{ 
                  maxWidth: '100%', 
                  maxHeight: '100%', 
                  objectFit: 'contain'
                }}
              />
              <IconButton 
                sx={{ 
                  position: 'absolute', 
                  top: 8, 
                  right: 8, 
                  bgcolor: 'rgba(0,0,0,0.5)',
                  color: colors.white,
                  '&:hover': {
                    bgcolor: 'rgba(0,0,0,0.7)'
                  }
                }}
                onClick={resetSelection}
              >
                <DeleteIcon />
              </IconButton>
            </Paper>
            <Typography variant="caption" sx={{ color: 'text.secondary', display: 'block', mt: 1 }}>
              {selectedFile?.name} ({Math.round(selectedFile?.size / 1024)} KB)
            </Typography>
          </Box>
        ) : (
          <Box
            component="label"
            htmlFor="upload-input"
            sx={{
              border: `2px dashed rgba(0, 0, 0, 0.2)`,
              borderRadius: 2,
              p: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              height: 200,
              transition: 'all 0.2s',
              '&:hover': {
                borderColor: colors.amber,
                bgcolor: 'rgba(255, 193, 7, 0.05)'
              }
            }}
          >
            <input
              id="upload-input"
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleFileChange}
              disabled={isUploading}
            />
            <CloudUploadIcon sx={{ fontSize: 48, color: colors.amber, mb: 2 }} />
            <Typography variant="body1" align="center" sx={{ color: colors.darkGray }}>
              Click to browse or drag and drop
            </Typography>
            <Typography variant="caption" align="center" sx={{ color: 'text.secondary', mt: 1 }}>
              JPG, PNG or GIF (please try max. upto 5MB)
            </Typography>
          </Box>
        )}
        
        {uploadError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            <AlertTitle>Upload Failed</AlertTitle>
            {uploadError}
          </Alert>
        )}
        
        {isUploading && (
          <Box sx={{ mt: 3 }}>
            <LinearProgress 
              variant="determinate" 
              value={uploadProgress} 
              sx={{ 
                height: 8, 
                borderRadius: 4,
                bgcolor: 'rgba(0, 0, 0, 0.08)',
                '& .MuiLinearProgress-bar': {
                  bgcolor: colors.purple
                }
              }} 
            />
            <Typography variant="caption" align="center" sx={{ display: 'block', mt: 1, color: 'text.secondary' }}>
              Uploading... {uploadProgress}%
            </Typography>
          </Box>
        )}
        
        {uploadComplete && !isUploading && (
          <Alert severity="success" sx={{ mt: 2 }}>
            <AlertTitle>Success</AlertTitle>
            Document uploaded successfully!
          </Alert>
        )}
      </DialogContent>
      
      <DialogActions sx={{ p: 2, bgcolor: 'background.default' }}>
        <Button 
          onClick={handleCancel}
          disabled={isUploading}
          sx={{ 
            color: colors.darkGray,
            '&:hover': {
              bgcolor: 'rgba(52, 58, 64, 0.08)'
            }
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleUpload} 
          disabled={!selectedFile || isUploading} 
          variant="contained"
          sx={{ 
            bgcolor: colors.amber,
            color: colors.darkGray,
            '&:hover': {
              bgcolor: '#e6ac00'
            },
            '&.Mui-disabled': {
              bgcolor: 'rgba(255, 193, 7, 0.5)',
              color: 'rgba(52, 58, 64, 0.5)'
            }
          }}
        >
          {isUploading ? 'Uploading...' : 'Upload'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageUploadModal;
